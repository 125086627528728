export const PMSpeaksSeriesData = [
    {
        thumbnail: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRdxolT13heGrYA4NAveljbU_8KItdLIXwMKLSEd4Pxmq9K-nEZ74YsjyZUkgRWmjCs7AY&usqp=CAU'
    },
    {
        thumbnail: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSmnqilW7wjwt6Kad14edMxNzr9KFYULDFzWTaJhEPezw9Ub92dM4TeM3tWPiUBi4CNjCQ&usqp=CAU'
    },
    {
        thumbnail: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQh9bVA3tfMMQYp98CkhipzwlDhdxhsQvtJlw&usqp=CAU'
    },
    {
        thumbnail: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTr45XFfVpxr3iWy07p0qt_q1dlGNNMl7vVEcEgcxPqxNdpw1ZGQ1hw27e7NG3Lriffrd8&usqp=CAU'
    },
]