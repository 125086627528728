export const podcastsData = [
    {
        thumbnail: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRUJYzzipAShvEoomuIn-KJ8js3kXZb4JrEmA&usqp=CAU'
    },
    
    {
        thumbnail: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTKeR9ldDHo50-f55e7-LvBUZQ7b3gsKF86Cw&usqp=CAU'
    },
    {
        thumbnail: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQhELAkG3Y0KUA769qIZRA9APVARZaABSxM1Q&usqp=CAU'
    },
    {
        thumbnail: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSg6Orv5NnT68CxatJ4YkZ29mnJPUHZKIETvQ&usqp=CAU'
    },
    {
        thumbnail: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTIZ2uSAR2jN-s9TZk5ncZ75PnaQGbWzL30fA&usqp=CAU'
    },
    {
        thumbnail: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRaraA3DUpnTcNu989nRptU4wyP1SoBI6MSXA&usqp=CAU'
    },
    {
        thumbnail: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQhELAkG3Y0KUA769qIZRA9APVARZaABSxM1Q&usqp=CAU'
    }
]