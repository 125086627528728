
export const videoData = [
    {
        thumbnail: 'https://www.neonmoire.com/assets/images/event/59db0f597652e2c0bc78aa409e19c1a86eea2d88_5b3542c610562',
        title: '',
        text: ''
    },
    {
        thumbnail: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQXIK-GXx94J0FEV-0ugLIA0Cgaaxk8Fo6UmQ&usqp=CAU',
        title: '',
        text: ''
    }
]